// Imports
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../node_modules/css-loader/dist/runtime/getUrl.js";
import ___CSS_LOADER_URL_IMPORT_0___ from "./fonts/HeroQuest.ttf";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "@font-face {\n  font-family: 'HeroQuest';\n  src: local('HeroQuest'), url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n}\n\n.App {\n  text-align: center;\n}\n\nheader {\n  position: relative;\n  top: -20px;\n}\n\nh1 {\n  font-family: HeroQuest;\n  font-size: 2.4em;\n  opacity: 0.9;\n  color: rgba(255, 217, 0, 0.9);\n  text-shadow: -2px -2px 0 darkred, 2px -2px 0 darkred, -2px 2px 0 darkred, 2px 2px 0 darkred, 0px 0px 10px rgba(31, 31, 31, 1);\n  margin: 0;\n  letter-spacing: -4px;\n}\n\n.hq_logo {\n  max-width: 90%;\n  width: 400px;\n  position: relative;\n  top: 25px\n}", "",{"version":3,"sources":["webpack://src/App.css"],"names":[],"mappings":"AAAA;EACE,wBAAwB;EACxB,gEAAmD;AACrD;;AAEA;EACE,kBAAkB;AACpB;;AAEA;EACE,kBAAkB;EAClB,UAAU;AACZ;;AAEA;EACE,sBAAsB;EACtB,gBAAgB;EAChB,YAAY;EACZ,6BAA6B;EAC7B,6HAA6H;EAC7H,SAAS;EACT,oBAAoB;AACtB;;AAEA;EACE,cAAc;EACd,YAAY;EACZ,kBAAkB;EAClB;AACF","sourcesContent":["@font-face {\n  font-family: 'HeroQuest';\n  src: local('HeroQuest'), url(./fonts/HeroQuest.ttf);\n}\n\n.App {\n  text-align: center;\n}\n\nheader {\n  position: relative;\n  top: -20px;\n}\n\nh1 {\n  font-family: HeroQuest;\n  font-size: 2.4em;\n  opacity: 0.9;\n  color: rgba(255, 217, 0, 0.9);\n  text-shadow: -2px -2px 0 darkred, 2px -2px 0 darkred, -2px 2px 0 darkred, 2px 2px 0 darkred, 0px 0px 10px rgba(31, 31, 31, 1);\n  margin: 0;\n  letter-spacing: -4px;\n}\n\n.hq_logo {\n  max-width: 90%;\n  width: 400px;\n  position: relative;\n  top: 25px\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
